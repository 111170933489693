.list-sale-card {
  display: flex;
  padding: 12px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  border-radius: 12px;
  background: #fff;
  width: 100%;
  height: 288px;

  .picture-container {
    position: relative;
    width: 337.5px;
    // height: 245.25px;
    min-width: 337px;
    height: 100%;
    max-height: 266px;
    @media only screen and (max-width: 900px) {
      // max-width: 100%;
      max-height: 245px;
    }
    .picture {
      width: 337.5px;
      // height: 245.25px;
      border-radius: 12px;
      height: 100%;
      object-fit: cover;
    }

    svg {
      position: absolute;
      top: 11px;
      left: 9px;
      cursor: pointer;
    }
  }

  .card-body {
    width: 100%;
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    margin-top: 9px;
    color: #1b1c57;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    text-transform: capitalize;

    .title-link {
      color: #1b1c57;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      text-transform: capitalize;
      cursor: pointer;
    }
  }

  .localisation-container {
    margin-top: 6px;
    display: flex;
    align-items: center;
    gap: 9px;

    span {
      color: rgba(29, 78, 216, 0.8);
      font-size: 10.5px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 230px;
    }
  }

  .description {
    margin-top: 6px;
    color: #626687;

    font-size: 10.5px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    // max-width: 450px;
    height: 37px;
    margin-left: 0 !important;
    // @media only screen and (max-width: 1135px) {
    //   max-width: 400px;
    // }
    // @media only screen and (max-width: 950px) {
    //   max-width: 300px;
    // }
    @media only screen and (max-width: 900px) {
      // max-width: 100%;
    }

    button {
      border: 0;
      padding: 1.5px 6px;
      border-radius: 9px;
      background: #875fdc;
      color: #1b1c57;

      font-size: 9px;
      font-style: normal;
      font-weight: 700;
      line-height: 16.5px;
      cursor: pointer;
    }
  }
  .data-container {
    min-width: 100px;
  }
  @media only screen and (max-width: 820px) {
    display: grid;
    justify-content: center;
    padding: 0;
    gap: 0;
    width: 500px;
    height: 100%;
    .card-body {
      padding: 8px;
    }

    .picture-container {
      justify-self: center;
      width: 100%;
      width: 500px;

      .picture {
        width: 100%;
        width: 500px;
        object-fit: cover;
        // border-radius: 12px 12px 0px 0px !important;
      }
    }
  }
  @media only screen and (max-width: 550px) {
    width: 400px;
    .picture-container {
      justify-self: center;
      width: 100%;
      width: 400px;
      height: 200px;

      .picture {
        width: 100%;
        width: 400px;
        object-fit: cover;
        height: 200px;
        // border-radius: 12px 12px 0px 0px !important;
      }
    }
  }
  @media only screen and (max-width: 430px) {
    width: 100%;
    .picture-container {
      justify-self: center;
      width: 100%;

      .picture {
        width: 100%;
        object-fit: cover;
        // border-radius: 12px 12px 0px 0px !important;
      }
    }
  }
}

.card-specifications-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  margin-top: 5.6px;

  button {
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2.8px;
    color: #1b1c57;

    font-size: 8px;
    padding: 2.8px 5.6px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    cursor: pointer;
    border-radius: 8.4px;
    background: rgba(196, 196, 196, 0.5);
  }

  .arrow {
    padding: 2.8px;
    width: 16.8;
    height: 16.8;
  }
}

.card-small {
  display: grid;
  justify-content: center;
  padding: 0;
  gap: 0;
  width: 320px;

  .card-body {
    padding: 8px;
  }

  .picture-container {
    justify-self: center;
    width: 100%;

    .picture {
      width: 100%;
    }
  }
}
.small {
  width: 345px;
  display: grid;
  padding: 0px;
  height: 100%;

  .picture-container {
    justify-self: center;
    width: 100% !important;
    min-width: 100%;
    width: 345px;

    .picture {
      width: 100% !important;
      min-width: 100%;
      width: 345px !important;
    }
  }
  .description {
    max-width: 290px;
  }
  .title {
    color: var(--mrt-app-headline, #1b1c57);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
    text-transform: capitalize;
  }
  .specifications {
    .tags {
      max-width: 230px !important;
    }
  }
  .ant-tabs-tab {
    padding: 5px 0 !important;
    margin: 0 5px !important;

    &:first-child {
      margin: 0;
    }
  }
  .card-body {
    width: 100%;
    padding: 0 10px 10px 10px;
  }
  .localisation-container {
    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 150px;
    }
  }
}

// .smoking_allowed_text {
//   font-size: 11px;
//   margin-bottom: -10px !important;
//   padding: 0;
//   .tag {
//     margin-top: 8px;
//     margin-bottom: 8px;
//   }
// }

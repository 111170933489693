.pdf {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px solid #626686;
  padding: 1px 7px;
  border-radius: 8px;
  width: fit-content;
  cursor: pointer;
  .pdf_title {
    font-size: 13px;
  }
}

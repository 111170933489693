.list-sale-filter {
  display: flex;
  justify-content: center;
  align-items: center;

  .list-sale-filter-container {
    display: flex;
    gap: 22px;
    padding: 18px 0;
    width: 80%;

    @media only screen and (max-width: 700px) {
      width: 100%;
    }
    .swiper {
      .swiper-wrapper {
        // gap: 12px;

        .swiper-slide {
          width: auto;
        }
      }
    }

    .btn {
      min-width: 35px;
      height: 35px;
      padding: 4px 8px 4px 8px;
      border-radius: 12px;
      cursor: pointer;
      background-color: #f4efff;
      border: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      @media only screen and (max-width: 700px) {
        display: none;
      }
    }

    .items-btn {
      min-width: 35px;
      height: 35px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: #1b1c57;
      font-size: 14px;
      font-weight: 600;
      padding: 4px 8px 4px 8px;
      border-radius: 12px;
      cursor: pointer;
      background-color: #f4efff;
      border: 0;
      line-height: 18px;
      user-select: none;
    }

    .items-btn-active {
      filter: brightness(90%);
    }
  }
}

.property_details {
  padding: 30px 150px 12px 150px;

  background: var(--bg, #f5f5f5);
  @media only screen and (max-width: 1589px) {
    padding: 30px 150px 12px 150px;
  }
  @media only screen and (max-width: 1091px) {
    padding: 12px 30px;
  }
  @media only screen and (max-width: 700px) {
    padding: 12px 20px;
  }
  .list-sale-filter {
    .full_tab {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  .tabs_details {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;

    @media only screen and (max-width: 420px) {
      .tabs {
        .ant-radio-group {
          .ant-radio-button-wrapper {
            padding: 0 12px;
            span {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .content {
    display: flex;
    width: 100%;
    gap: 60px;
    max-width: 1400px;

    @media only screen and (max-width: 900px) {
      flex-direction: column-reverse;
      gap: 0;
    }

    .left {
      flex: 1;
      width: 100%;
      .smoking_tag {
        .tag {
          margin-bottom: 15px;
          background: var(--light-green, #f4efff);
          color: var(--dark-blue, #1b1c57);
          font-size: 9px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;
        }
      }

      .title {
        color: var(--mrt-app-headline, #1b1c57);
        font-size: 27px;
        font-style: normal;
        font-weight: 600;
        line-height: 36px;
        text-transform: capitalize;
        margin-bottom: 6px;
        @media only screen and (max-width: 900px) {
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 133.333% */
          text-transform: capitalize;
        }
      }
      .position {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 6px;

        color: rgba(29, 78, 216, 0.8);
        font-size: 10.5px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        @media only screen and (max-width: 700px) {
          color: rgba(29, 78, 216, 0.8);
          font-size: 8px;
          font-style: normal;
          font-weight: 400;
          line-height: 12px; /* 150% */
          gap: 4px;
        }
      }

      .description {
        color: var(--mrt-app-neutral-01, #626687);
        font-size: 13.5px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        margin-bottom: 12px;
        word-wrap: break-word;
        max-width: 500px;
        margin-left: 0 !important;
        @media only screen and (max-width: 1135px) {
          max-width: 400px;
        }
        @media only screen and (max-width: 950px) {
          max-width: 350px;
        }
        @media only screen and (max-width: 900px) {
          max-width: 100%;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 12px; /* 120% */
        }
      }

      .items {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-bottom: 12px;
      }
      .post_date {
        color: var(--dark-blue, #1b1c57);
        font-size: 10.5px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 171.429% */
        margin-bottom: 10px;
        margin-top: 12px;
      }
      .share {
        display: flex;
        align-items: center;
        gap: 6px;

        .value {
          display: flex;
          align-items: center;
          gap: 6px;
        }

        .title {
          color: var(--dark-blue, #1b1c57);
          font-size: 10.5px;
          font-style: normal;
          font-weight: 700;
          line-height: 16.5px; /* 157.143% */
        }
      }
      .ref_num {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        gap: 6px;

        .title_ref {
          color: var(--dark-blue, #1b1c57);
          font-size: 10.5px;
          font-style: normal;
          font-weight: 700;
          line-height: 16.5px;
          /* 157.143% */
        }

        .value_ref {
          color: var(--dark-blue, #1b1c57);
          font-size: 10.5px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          /* 171.429% */
          display: flex;
          align-items: center;
          gap: 3px;
        }
      }

      .property_num {
        margin-bottom: 70px;
        display: flex;
        align-items: center;
        gap: 6px;

        .title_ref {
          color: var(--dark-blue, #1b1c57);
          font-size: 10.5px;
          font-style: normal;
          font-weight: 700;
          line-height: 16.5px;
          /* 157.143% */
        }

        .value_ref {
          color: var(--dark-blue, #1b1c57);
          font-size: 10.5px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          /* 171.429% */
          display: flex;
          align-items: center;
          gap: 3px;
        }
      }
      .pdf_section {
        margin-bottom: 22px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 0px;
      }
      .back {
        display: flex;
        align-items: center;
        gap: 10px;
        color: var(--mrt-app-headline, #1b1c57);
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 36px; /* 200% */
        text-transform: capitalize;
        cursor: pointer;

        @media only screen and (max-width: 900px) {
          margin-top: 8px;

          span {
            display: none;
          }
        }
      }
    }
    //right
    .right {
      flex: 1;
      width: 100%;
      max-width: 50%;
      direction: ltr;

      @media only screen and (max-width: 900px) {
        max-width: 100%;
      }
      .ant-image {
        width: 100%;
        .ant-image-mask {
          margin-bottom: 20px !important;
          border-radius: 12px;
        }
      }
      .principal_img {
        width: 100%;
        object-fit: fill;
        height: 390px;
        margin-bottom: 20px;
        border-radius: 12px;

        @media only screen and (max-width: 600px) {
          // display: none;
          height: 200px;
        }
        img {
          width: 100% !important;
        }
      }

      .slider-mobile {
        display: none;
      }

      @media only screen and (max-width: 900px) {
        .slider-pc {
          // display: none;
        }

        .slider-mobile {
          display: block;
        }
      }
    }
  }

  .slider {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 22px;

    .swiper {
      .swiper-wrapper {
        .swiper-slide {
          img {
            width: 100%;
            height: 75px;
            border-radius: 12px;
            user-select: none;
          }
        }
      }
    }

    @media only screen and (max-width: 900px) {
      position: relative;

      .btn-left {
        position: absolute;
        top: 42%;
        left: 25px;
        z-index: 4;
        padding: 0 6px;
        cursor: pointer;
      }

      .btn-right {
        position: absolute;
        top: 42%;
        right: 25px;
        z-index: 4;
        padding: 0 6px;
        cursor: pointer;
      }

      .swiper {
        .swiper-wrapper {
          .swiper-slide {
            img {
              height: 100%;
            }
          }
        }
      }
    }
  }

  .map {
    margin-bottom: 15px;

    @media only screen and (max-width: 900px) {
      display: none;
    }
  }
  .map {
    & > div {
      height: 275px !important;
      & > div {
        border-radius: 6px;
      }
    }
  }
  .map-mobile {
    display: none;
    // margin-top: -50px;

    @media only screen and (max-width: 900px) {
      display: block;
    }
  }
}
.spin_page {
  height: 100vh;
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 500px;
}

//mobile swiper

.slider-mobile {
  margin-bottom: 20px;
  .ant-image {
    height: 360px !important;
    @media only screen and (max-width: 489px) {
      height: 200px !important;
    }
  }
  .ant-image {
    width: 100%;
    .ant-image-mask {
      margin-bottom: 13px;
      border-radius: 12px;
      height: 100%;
    }
  }
  .swiper {
    height: 360px !important;
    @media only screen and (max-width: 489px) {
      height: 200px !important;
    }
  }
}

//slider_web_images
.slider_web_images {
  margin-bottom: 20px !important;
  .swiper {
    height: 76px !important;
    .swiper-slide {
      max-width: 200px;
    }
    @media only screen and (max-width: 489px) {
      height: 76px !important;
    }
  }
}
//slider_web_videos
.slider_web_videos {
  margin-bottom: 20px;
  .swiper {
    height: 76px !important;
    .swiper-slide {
      max-width: 200px;
    }
    @media only screen and (max-width: 489px) {
      height: 76px !important;
    }
  }
}

//video web and responsive
.full {
  height: 390px;
  margin-bottom: 20px !important;
  @media only screen and (max-width: 900px) {
    height: 360px !important;
  }
  @media only screen and (max-width: 489px) {
    height: 200px !important;
  }
}

//pdfs sections stytles

// .pdf_section {
//   display: flex;
//   align-items: center;
//   justify-content: flex-start;
//   gap: 40px;
//   flex-wrap: wrap;
//   margin-bottom: 8px;
//   @media only screen and (max-width: 600px) {
//     justify-content: center;
//   }
// }
.pdf_section {
  margin-bottom: 22px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  flex-wrap: wrap;
}
.one_slider_images {
  margin-top: -30px;
}

.profile-sale {
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .content-container {
    width: 80%;
    display: flex;
    justify-content: center;
    gap: 21px;
  }

  .tabs {
    align-self: center;
    margin-bottom: 24px;

    .tabs-btn {
      background-color: #ffffff;
      height: 48px !important;
      padding: 0 24px;
      border: 0 !important;

      &::before {
        display: none !important;
      }

      span {
        border: 0 !important;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        color: #875fdc;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        text-transform: capitalize;
      }

      @media only screen and (max-width: 420px) {
        padding: 0 12px;

        span {
          font-size: 12px;
        }
      }
    }

    .ant-radio-button-wrapper-checked {
      background-color: #875fdc;
      color: #ffffff;

      span {
        color: #ffffff;
      }
    }
  }
}

.pricing-filters {
  display: flex;
  flex-direction: column;
  gap: 6px;
  .btn {
    padding: 3px 6px;
    border-radius: 9px;
    border: 0.75px solid #875fdc;
    background: #fff;
    color: #875fdc;

    font-size: 9px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    cursor: pointer;
    white-space: nowrap;
  }

  .btn-active {
    background: #875fdc;
    color: #fff;
  }
  hr {
    border: 0.5px solid rgba(196, 196, 196, 0.5);
  }

  .label {
    color: #1b1c57;
    font-size: 10.5px;

    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    white-space: nowrap;
  }

  .price-determined-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .checkbox-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .checkbox-input {
      flex: 1;
      gap: 8px;
    }
  }
  .btns-list {
    display: flex;
    gap: 20px;
    margin-top: 10px;
  }
  .calendar-btn {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}

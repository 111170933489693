.list-sale {
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 100px;
  @media only screen and (max-width: 1350px) {
    padding: 0 50px;
  }
  @media only screen and (max-width: 950px) {
    padding: 0 30px;
  }
  @media only screen and (max-width: 550px) {
    padding: 0 10px;
  }
  .swipers {
    margin-bottom: 20px;
  }
  .list-sale-filter {
    display: flex;
    justify-content: center;
    align-items: center;

    .list-sale-filter-container {
      display: flex;
      gap: 22px;
      padding: 8px 0;
      // width: 90%;
      width: 100%;

      @media only screen and (max-width: 700px) {
        width: 100%;
      }
      .swiper {
        .swiper-wrapper {
          // gap: 12px;

          .swiper-slide {
            width: auto;
          }
        }
      }

      .btn {
        min-width: 35px;
        height: 35px;
        padding: 4px 8px 4px 8px;
        border-radius: 12px;
        cursor: pointer;
        background-color: #f4efff;
        border: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        @media only screen and (max-width: 700px) {
          display: none;
        }
      }

      .items-btn {
        min-width: 35px;
        height: 35px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #1b1c57;
        font-size: 14px;
        font-weight: 600;
        padding: 4px 8px 4px 8px;
        border-radius: 12px;
        cursor: pointer;
        background-color: #f4efff;
        border: 0;
        line-height: 18px;
        user-select: none;
      }

      .items-btn-active {
        filter: brightness(90%);
      }
    }
  }

  .tabs {
    align-self: center;
    margin-bottom: 24px;

    .tabs-btn {
      background-color: #ffffff;
      height: 48px !important;
      padding: 0 24px;
      border: 0 !important;

      &::before {
        display: none !important;
      }

      span {
        border: 0 !important;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        color: #875fdc;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        text-transform: capitalize;
      }

      @media only screen and (max-width: 420px) {
        padding: 0 12px;

        span {
          font-size: 12px;
        }
      }
    }

    .ant-radio-button-wrapper-checked {
      background-color: #875fdc;
      color: #ffffff;

      span {
        color: #ffffff;
      }
    }
  }

  .content-container {
    display: flex;
    justify-content: center;

    .content {
      margin-bottom: 195px;
      // width: 90%;
      width: 100%;

      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 48px;

      .right-content-container {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
        .menu-search {
          display: flex;
          align-items: center;
          gap: 10px;
        }
        .cards-container {
          display: flex;
          flex-direction: column;
          gap: 24px;
          @media only screen and (max-width: 820px) {
            align-items: center;
          }
          // width: 100%;
        }
      }
    }

    .org-card {
      display: flex;
      width: 80%;
      padding: 6px;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 12px;
      border-radius: 6px;
      background: #fff;
      margin-bottom: 12px;

      .orgtitle {
        color: #1b1c57;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px; /* 133.333% */
        text-transform: capitalize;
      }

      .localisation-container {
        display: flex;
        align-items: center;
        color: rgba(29, 78, 216, 0.8);
        font-size: 10.5px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 171.429% */
      }

      .social-media {
        display: flex;
        align-items: center;
        gap: 6px;
        margin-top: 6px;
      }

      .value_ref {
        color: #1b1c57;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 171.429% */
      }

      @media only screen and (max-width: 1255px) {
        display: none;
      }
    }
  }

  @media only screen and (max-width: 1255px) {
    .content-container {
      .content {
        .filters-content-container {
          display: none;
        }
      }
    }
  }
}

.filters-modal-container {
  width: 342px !important;
  margin-top: 24px;
  margin-bottom: 24px;
}

.map-show-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px;
}

.smaller-content-container {
  justify-content: space-between;
  width: 100%;
  // gap: 24px !important;

  .right-content-container {
    width: 100%;

    @media only screen and (max-width: 860px) {
      .map-show-container {
        gap: 0;
        .cards-container {
          display: none !important;
        }
      }
    }
  }
}

.menu-toggle-icon {
  border-radius: 9px;
  background: rgba(196, 196, 196, 0.5);
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.menu-toggle-icon-active {
  background: #fff;
}

@media only screen and (max-width: 420px) {
  .list-sale {
    .content-container {
      .content {
        // width: 95%;

        .right-content-container {
          justify-content: center;
        }
      }
    }
  }
}

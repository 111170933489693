.navigation__container {
  display: flex;
  flex-direction: column;
  gap: 17px;
  align-items: flex-start;
  width: 100%;
  & > .navigation_link {
    display: flex;
    align-items: center;
    gap: 14px;
    color: #ffff;
    & > svg {
      stroke: #ffff;
    }

    span {
      color: var(--white, #fff);
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
    }
  }

  .submit_prop {
    display: flex;
    padding: 10px 16px;
    align-items: center;
    gap: 10px;

    border-radius: 12px;
    background: var(--basic, #875fdc);
    color: var(--white, #fff);

    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    border: none;
    cursor: pointer;
  }
  .login_sidebar {
    display: flex;
    padding: 10px 16px;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    background: var(--white, #fff);

    color: var(--dark-blue, #1b1c57);
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    border: none;
    cursor: pointer;
  }
  .logout_sidebar {
    display: flex;
    padding: 9px 16px;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    background: var(--red, #ff1d1d);
    border: none;
    cursor: pointer;
    color: var(--white, #fff);
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }
}

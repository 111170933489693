.property {
  padding: 22px 8px;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .header-title {
      color: #1b1c57;
      text-align: center;
      font-size: 20px;

      font-weight: 700;
      text-transform: capitalize;
    }

    .header-subtitle {
      color: rgba(0, 0, 0, 0.5);
      text-align: center;
      font-size: 14px;

      text-transform: capitalize;
    }

    .input-container {
      margin-top: 22px;
      border-radius: 9px;
      background: #fff;
      display: flex;
      padding: 9px 18px;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
      width: 100%;
      max-width: 325px;

      input {
        border: 0;
        width: 100%;
        outline: none !important;
      }

      .register-btn {
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        text-transform: capitalize;
        color: #1b1c57;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: none;
        color: #ffffff;
        background: #875fdc;
        border-radius: 12px;
        padding: 8px 16px;
      }
    }

    @media only screen and (max-width: 768px) {
      width: 100%;

      .input-container {
        max-width: 85%;
      }
    }
  }

  .content-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    margin-top: 22px;
    width: 80%;

    .select-data-container {
      min-width: 270px;

      .sale-select-container {
        background-color: rgba(196, 196, 196, 0.2);
        border: 0;
      }
    }

    .register-btn {
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      text-transform: capitalize;
      color: #1b1c57;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border: none;
      color: #ffffff;
      background: #875fdc;
      border-radius: 12px;
      padding: 8px 16px;
      width: 100%;
      margin-top: 12px;
    }

    .map {
      border-radius: 6px;
      width: 100%;
      height: 400px;
      border: 1px solid #eee;
      overflow: hidden;
    }
  }

  @media only screen and (max-width: 768px) {
    .content-container {
      flex-direction: column;
      width: 95%;

      .select-data-container {
        width: 90%;
        align-self: center;
      }
    }
  }
}

.card-jawla {
  // width: 400px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 12px;
  background: #fff;
  width: 100%;
  flex-direction: column;
  min-width: 300px;
  .media {
    width: 100%;
    position: relative;
    .image-main {
      width: 100%;
      border-radius: 15px 15px 0 0;
      height: 250px;
      cursor: pointer;
    }
    .fav {
      position: absolute;
      top: 10px;
      left: 10px;
      cursor: pointer;
    }
  }

  .content-jawla {
    padding: 12px;
    width: 100%;

    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;

    // height: 100%;
    .title-jawla {
      margin-top: 9px;
      color: #1b1c57;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      text-transform: capitalize;
      margin-bottom: 5px;
      cursor: pointer;
    }
    .location {
      display: flex;
      align-items: center;
      gap: 5px;
      p {
        color: rgba(29, 78, 216, 0.8);
        font-size: 10.5px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 230px;
      }
    }
    .description-jawla {
      margin-top: 13px;
      color: #626687;

      font-size: 13.5px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;

      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      word-wrap: break-word;
      // max-width: 450px;
      // height: 37px;
      margin-left: 0 !important;
      margin-bottom: 20px;
    }

    .action {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .contatt {
        display: flex;
        align-items: center;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 12px;

        .ref-id {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0;
          color: #875fdc;
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: 16.5px;
          cursor: pointer;
        }

        .contact-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          width: 28px;
          height: 28px;
          border: 0;
          border-radius: 9px;
          background: #f4efff;
        }
      }
      .more-details {
        font-size: 13.5px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        cursor: pointer;
        color: rgba(29, 78, 216, 0.8);
      }
    }
  }
}

.tooltip-color {
  color: black !important;
  font-size: smaller;
}